var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h2',{staticClass:"heating-oil-consumption-calculator__title"},[_vm._v("\n    Heizölverbrauchs-Rechner für "+_vm._s(_vm.zipcode)+" "+_vm._s(_vm.city)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"heating-oil-consumption-calculator"},[_c('div',{staticClass:"heating-oil-consumption-calculator__container"},[_c('div',{staticClass:"heating-oil-consumption-calculator__select-group"},[_c('EsySelect',{attrs:{"options":_vm.energyClassConsumption,"label":"Gebäude-Effizienz","placeholder":"Bitte wählen"},on:{"emit-selected-value":_vm.handleEnergyClassChange}})],1),_vm._v(" "),_c('div',{staticClass:"heating-oil-consumption-calculator__input-group"},[_c('EsyInput',{attrs:{"id":"Quadratmeter","variant":"bordered","type":"number","placeholder":"100","label":"Quadratmeter","value":_vm.squareMeters},on:{"emit-input-value":_vm.handleSquareMeterChange}})],1),_vm._v(" "),_c('div',{staticClass:"heating-oil-consumption-calculator__input-group"},[_c('EsyButton',{class:`step-forward-button is--animated`,attrs:{"variant":"small-secondary"},nativeOn:{"click":function($event){return _vm.calculateOilConsumption()}}},[_vm._v("\n          Rechnen\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"heating-oil-consumption-calculator__result-group"},_vm._l((_vm.resultItems),function(item,index){return _c('div',{key:index,staticClass:"heating-oil-consumption-calculator__result-item",class:[
            `heating-oil-consumption-calculator__result-item--${item.class}`,
            {
              'heating-oil-consumption-calculator__result-item--inactive':
                !_vm.hasResults,
            },
          ]},[_c('span',{staticClass:"heating-oil-consumption-calculator__result-value"},[_vm._v(_vm._s(_vm.hasResults ? item.value : "0")+_vm._s(item.unit))]),_vm._v(" "),_c('span',{staticClass:"heating-oil-consumption-calculator__result-unit"},[_vm._v(_vm._s(item.period))])])}),0)]),_vm._v(" "),(_vm.showErrorMessage)?_c('span',{staticClass:"heating-oil-consumption-calculator__error-message"},[_vm._v("\n      Bitte Gebäude-Effizienz auswählen\n    ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }